/**
 * 配置创建API
 */
import { fetch, post, del } from 'api/base/axios'

const URL = {
  addUrl: '/configure/add/',
  getUrl: '/configure/list/',
  getAdminUrl: '/configure/admin/list/'
}

/**
 * 添加配置
 * @param data
 * @returns {Promise}
 */
export function add_configure (data) {
  return post(URL.addUrl, data)
}

export function list_configures () {
  return fetch(URL.getUrl)
}

export function list_admin_configures(){
  return fetch(URL.getAdminUrl)
}
